import React from "react";
import Layout from "../components/layout";
import { Div } from "atomize";
import { Button, Heading, Image, Text } from "@chakra-ui/react";
import Logo from "../images/logo.png";
// import GetStartedBanner from "../components/homepage/GetStartedBanner"

const Page = () => {
  return (
    <Layout>
      <Div
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
        h="100vh"
        style={{ padding: 50 }}
      >
        <Div className="wrapperAlert">
          <Div>
            <Div>
              <Div align="center" d="flex" justify="center" flexDir="column">
                <Image src={Logo} alt="File here" w="150px" mb={5} />
              </Div>

              <Heading my={2} color="black" fontWeight="bold">
                Se ha cancelado el pago
              </Heading>
            </Div>

            <Div>
              <Button colorScheme="blue">Ir al dashboard</Button>
            </Div>
          </Div>
        </Div>
      </Div>
    </Layout>
  );
};

export default Page;
